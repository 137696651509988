<div *ngIf="currentUser.userId !== ''" class="user-profile-container">
  <div class="controls d-flex align-items-center justify-content-end flex-wrap">
    <div ngbDropdown
         data-test-id="user-menu"
         placement="bottom-right"
         class="mr-2 user-menu-container">
      <button ngbDropdownToggle
              id="userBtn"
              class="btn btn-nav">
        {{ currentUser?.name }}
        <reporter-icon class="inline-flex"
                       icon="Person"></reporter-icon>
      </button>
      <div ngbDropdownMenu
           aria-labelledby="user-menu">
        <button *ngIf="isAdminUser || isLimitedAdminUser"
                ngbDropdownItem
                class="dropdown-item"
                (click)="openAdmin()"
                data-test-id="logout-btn">Administration
        </button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem
                class="dropdown-item"
                (click)="logout.emit()"
                data-test-id="logout-btn">Logout
        </button>

      </div>
    </div>

    <div *ngIf="clients?.length"
         class="separator ml-3">|
    </div>
    <span *ngIf="clients?.length === 1 && currentClient?.clientNumber !== 'SYSTEM'"
          class="clients-count text-only">Client: {{ currentClient?.clientNumber }}</span>

    <div *ngIf="clients?.length > 1 || (clients?.length === 1 && currentClient?.clientNumber === 'SYSTEM')"
         ngbDropdown
         data-test-id="user-menu"
         placement="bottom-right"
         class="d-inline-block mr-2">
      <button class="btn btn-nav-dropdown"
              id="clientBtn"
              ngbDropdownToggle
              [disabled]="disableClientChange">
        Client: {{ currentClient?.clientNumber }}
      </button>
      <div ngbDropdownMenu
           aria-labelledby="user-menu"
           class="clients-menu">
        <button *ngFor="let client of clients; let index = index"
                ngbDropdownItem
                class="dropdown-item"
                [class.active]="client.clientNumber === currentClient?.clientNumber"
                data-test-id="profile-btn"
                (click)="openWarningModal(client);"
                placement="top-left"
                display="dynamic">({{ client?.clientNumber }}) {{ client?.clientName }}
        </button>
        <ng-container *ngIf="isSystemUser">
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem
                  class="dropdown-item"
                  (click)="openClientsDialog()"
                  data-test-id="logout-btn">Search for Clients
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="firstconnect-logo text-right">
    <img src="assets/images/gbgofirstconnect100.svg">
  </div>
</div>
<div class="warning-dialog">
  <reporter-modal [show]="isWarningModalOpen"
                  [title]="'Are you sure?'"
                  [modalClasses]="'modal-warning'"
                  (onClose)="dismissWarningModal()">
    <div role="modal-body"
         class="text-dark text-center">
      Changing to different client will cause page reload! All of you currently filled data will be lost!
    </div>
    <div role="modal-footer"
         class="d-flex justify-content-center w-100">
      <button class="btn btn-action mr-2 warning"
              (click)="selectClient(selectedClient)">Yes, Continue
      </button>
      <button class="btn btn-action"
              (click)="dismissWarningModal()">Dismiss
      </button>
    </div>
  </reporter-modal>
</div>

<reporter-select-client-dialog *ngIf="showClientsDialog"
                               [searchDialog]="isSystemUser"
                               (selectClient)="selectTempClient($event)">
</reporter-select-client-dialog>
